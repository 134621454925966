body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

a {
    color: #e74c3c;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

a:hover {
    color: #e76c3c;
}

.row {
    padding: 80px 0px;
}

.btn {
    border: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    text-transform: uppercase;
    border-radius: 0;
}

.btn-common {
    background: #3d566e;
    color: #fff;
}

.btn-common:hover {
    background: #e74c3c;
    color: #fff;
}

.title {
    text-align: center;
    font-size: 50px;
}

.subtitle {
    text-align: center;
    font-weight: 100;
    padding-bottom: 30px;
    position: relative;
    color: #bdc3c7;
}

.navbar {
    margin-bottom: 0;
}

.navbar li {
    color: #d1d1d1;
}

.navbar-default {
    border: none;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.2);
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    background: none;
    color: #fff;
}

.navbar-default .navbar-nav li a,
.navbar-default .navbar-nav li a:hover {
    color: #fff;
}

.navbar-default .navbar-nav>.active,
.navbar-default .navbar-nav li:hover {
    background: #e74c3c;
    padding-bottom: 5px;
    color: #fff;
}

.navbar-default .navbar-brand {
    font-size: 25px;
    color: #fff;
    font-weight: 100;
}

.navbar-default .navbar-brand:hover {
    color: #eee;
}

.navbar-default .navbar-toggle .icon-bar {
    background: #fff;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background: #333;
}

.affix.navbar-default {
    background: rgba(0, 0, 0, 0.8);
}

#hero-area {
    background: #34495e;
    color: #fff;
}

#hero-area .btn {
    margin-right: 20px;
}

#hero-area img {
    max-width: 100%;
}

#hero-area p {
    padding: 50px 0;
}

#services h3,
.h3 {
    margin-top: 0;
    color: #e74c3c;
}


/*Portfolio*/

#portfolio {
    background: #f1f1f1;
}


/*Portfolio Overlay */

.portfolio-item {
    position: relative;
    margin-bottom: 15px;
}

.portfolio-item img {
    width: 100%;
}

.portfolio-item .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
}

.portfolio-item:hover .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.8);
    display: block;
}

.portfolio-item .icons .preview {
    position: absolute;
    left: 40%;
    top: 40%;
    color: #fff;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.portfolio-item .icons .preview i {
    color: #e74c3c;
    cursor: pointer;
}

#clients {
    background: #fff;
}

#about {
    background: #34495e;
    color: #fff;
}

#about p {
    line-height: 30px;
    font-size: 17px;
}

#contact {
    background: #f1f1f1;
}

#contact ::-webkit-input-placeholder {
    color: #ecf0f1;
}

#contact :-moz-placeholder {
    color: #ecf0f1;
}

#contact ::-moz-placeholder {
    color: #ecf0f1;
}

#contact :-ms-input-placeholder {
    color: #ecf0f1;
}

#contact .social i {
    color: #fff;
    padding: 10px;
    margin-right: 5px;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    background: #34495e;
}

#contact .social .social-links a:hover {
    color: #fff;
}

#contact .social .fa-facebook:hover {
    background: #3b5998;
    color: #fff;
}

#contact .social .fa-twitter:hover {
    background: #00CAFB;
    color: #fff;
}

#contact .social .fa-google-plus:hover {
    background: #D23A1D;
    color: #fff;
}

#contact .social .fa-linkedin:hover {
    background: #007bb6;
    color: #fff;
}

#contact .social .fa-dribbble:hover {
    background: #ea4c89;
    color: #fff;
}

#contact .social .fa-github:hover {
    background: #171515;
    color: #fff;
}

#contact .contact-info {
    margin: 40px 0;
}

#contact .contact-info p {
    font-size: 20px;
}

#contact .contact-form .form-control {
    box-shadow: none;
    font-size: 17px;
    margin-bottom: 20px;
    border-radius: 0;
    border: none;
}


/*Scroll Top*/

.to-top i {
    padding: 10px 15px;
    margin: 10px;
    background: transparent;
    color: #34495e;
    border: 2px solid #34495e;
}

.to-top i:hover {
    background: #34495e;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#copyright {
    background: #F1F1F1;
    color: #333;
}

#copyright a {
    color: #333;
}

.service-item img {
    width: 250px;
    height: 250px;
    object-fit: contain;
}

#my-contacts {
    background: #34495e;
    color: #fff;
    font-size: 17px;
}

#my-contacts > .container > .row {
    padding: 40px!important;
}